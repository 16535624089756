/* Shake animation */
@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
  }
  
  .shake {
    animation: shake 0.6s;
  }
  
  /* Red border */
  .border-red {
    border-color: red;
  }
  